
const submitWaiting = (() => {
	console.log('OKOK4');
	const form = document.querySelector('[data-waiting]');

	// on click, hide the link and show the form
	form.addEventListener('submit', e => {

		e.preventDefault();

        const formData = new FormData(form);
        const json = JSON.stringify(Object.fromEntries(formData));

        // submit the form using fetch
        fetch('/api/add', {
            method: 'POST',
		    headers: {
		      'Content-Type': 'application/json'
		    },
            body: json,
        })
        .then(_response => {
        	console.error('Waiting list - OK');
        	document.getElementById("step1").classList.add("d-none");
        	document.getElementById("step2").classList.remove("d-none");
        })
        .catch(_error => {
            console.error('Waiting list - OK');

        });
	});
})();

export default submitWaiting;
